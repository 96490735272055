@import 'app.scss';
.wrapper {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / -1;
  grid-gap: spacing(2);
  align-items: center;
  border-radius: var(--border-radius-base);
  background-color: var(--color-bg-brand);
  padding: spacing(6);
  color: var(--color-fg-brand);

  @include from-breakpoint(md) {
    padding: spacing(12);
  }
}

.content {
  grid-column: 1 / -1;
  margin-bottom: 0;
  padding-right: calc(var(--content-padding) * 2);
  padding-left: var(--content-padding);

  @include from-breakpoint(md) {
    grid-column: 1 / span 6;
  }
}

.formContent {
  grid-column: 1 / -1;
  margin-top: spacing(6);

  @include from-breakpoint(md) {
    grid-column: 7 / span 6;
    margin-top: 0;
  }
}

.primary {
  background-color: var(--color-bg-primary);
  color: var(--color-fg-primary);
}

.secondary {
  background-color: var(--color-bg-secondary);
  color: var(--color-fg-secondary);
}

.gray {
  background-color: var(--color-black-300);
  color: var(--color-black);
}

[data-theme='BREDA_STRAALT'] {
  .wrapper {
    background-color: var(--color-breda-straalt);
    color: var(--color-white);
  }
}
